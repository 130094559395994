export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DD HH:mm'
export const DEFAULT_IDLE_TIME = 59 * 60 * 1000 // 59 mins
export const ROLES = {
    ADMIN: 'Admin',
    SUPERADMIN: 'SuperUser',
    SUPPLIER: 'Suppliers',
    STDUSER: 'StandardUser',
    SUPERREADER: 'PowerReader',
    VCCPARTNER: 'VccPartner'
}
export const HOME_PAGE_HEADING = 'Welcome to SCOT'
export const HOME_PAGE_SUBHEADING = 'SCOT - Engineering Statement of Work (ESOW)'
export const MAPPED_TO_FIELD = [
    { key: 'art', text: 'Department' },
    { key: 'purchasing_id', text: 'Buyer ID' },
    { key: 'function_group', text: 'Function Group' },
    { key: 'platform_program', text: 'Program/Project' },
    { key: 'purchasing_team', text: 'Procurement Team' },
    { key: 'product_modules', text: 'Product Modules' },
    { key: 'project', text: 'Project' },
    { key: 'solution', text: 'Unit' }
]
export const NEWS_CARD_WIDTH = 270
export const NEWS_CARD_HEIGHT = 320

export enum ESOWSourcingCategory {
    BuildtoPrint = 4,
    DevelopmentSupplier = 5
}

export const CONTRIBUTORS_TAB = 'contributors'
export const SIGNATURE_TAB = 'signatures'
export const INTRODUCTION_TAB = 'introduction'
export const MAIN_TAB = 'main'
