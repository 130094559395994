import React, { useCallback, useEffect, useState } from 'react'
import { IContextualMenuItem, IStackTokens, Stack } from '@fluentui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from '../../../routes'
import ComdBarButton from '../CommandBarButton'
import DropdownNavComponent from '../DropdownNav'
import AboutModal from 'components/molecules/About/AboutModal'
import { ROLES } from 'utils/CONSTANTS'
import { useAccount, useMsal } from '@azure/msal-react'

const outerStackTokens: IStackTokens = { childrenGap: 5 }

export const Navbar = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [aboutPanelOpen, setAboutPanelOpen] = useState(false)
    const { accounts } = useMsal()
    const account: any = useAccount(accounts[0])
    const roles = account ? account?.idTokenClaims?.roles : []

    const navigateToRoute = (item: any) => {
        item.path.substring(0, 4) === 'http'
            ? window.open(item.path, '_blank')
            : item.path.substring(0, 7) === '<About>'
            ? setAboutPanelOpen(true)
            : navigate(item.path, { state: { from: pathname } })
    }

    const [helpMenuItems, setHelpMenuItems]: any = useState([])
    const headerNavRoutesHelp = routes.filter((item) => item?.navPlacement === 'help')
    const updateNavHeadHelp = useCallback(() => {
        const navRoutes: IContextualMenuItem[] = []
        headerNavRoutesHelp.forEach((item) => {
            const newMenuItem: any = {
                key: item.path,
                text: item.text,
                onClick: () => navigateToRoute(item)
            }
            navRoutes.push(newMenuItem)
        })
        setHelpMenuItems(navRoutes)
        return navRoutes
    }, [])

    const [viewMenuItems, setViewMenuItems]: any = useState([])
    const headerNavRoutesViewIndex = routes.findIndex((item) => item?.navPlacement === 'view')
    const headerNavRoutesView = routes.filter((item) => item?.navPlacement === 'view')
    const updateNavHeadView = useCallback(() => {
        const navRoutes: IContextualMenuItem[] = []
        headerNavRoutesView.forEach((item) => {
            const newMenuItem: any = {
                key: item.path,
                text: item.text,
                onClick: () => navigateToRoute(item)
            }
            if (item.key !== 'allESOW') {
                navRoutes.push(newMenuItem)
            } else {
                if (
                    roles.includes(ROLES.ADMIN) ||
                    roles.includes(ROLES.SUPERADMIN) ||
                    roles.includes(ROLES.SUPERREADER)
                ) {
                    navRoutes.push(newMenuItem)
                }
            }
        })
        setViewMenuItems(navRoutes)
        return navRoutes
    }, [])

    useEffect(() => {
        updateNavHeadView()
        updateNavHeadHelp()
    }, [])

    return (
        <>
            <Stack
                horizontal
                tokens={outerStackTokens}
                className='rowPadding'
                data-cy='headerNavbar'
            >
                {routes.map((item: any, index: number) => {
                    return item.mainNav && item?.navPlacement !== 'help' ? (
                        item?.navPlacement === 'view' ? (
                            index === headerNavRoutesViewIndex && (
                                <Stack.Item key={item.key}>
                                    <DropdownNavComponent
                                        menuItems={viewMenuItems}
                                        menuText='View'
                                    />
                                </Stack.Item>
                            )
                        ) : (
                            <Stack.Item key={item.key}>
                                <ComdBarButton
                                    label={item.text}
                                    iconProps={item.icon && { iconName: item.icon }}
                                    onClick={() => navigateToRoute(item)}
                                    className={`navBarItems ${
                                        item?.path === pathname && `navBarItemActive`
                                    }`}
                                    title={item.pageTitle}
                                />
                            </Stack.Item>
                        )
                    ) : (
                        ''
                    )
                })}
                <Stack.Item>
                    <DropdownNavComponent menuItems={helpMenuItems} menuText='Help' />
                </Stack.Item>
            </Stack>
            <AboutModal aboutPanelOpen={aboutPanelOpen} setAboutPanelOpen={setAboutPanelOpen} />
        </>
    )
}
