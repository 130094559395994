import React from 'react'
import './_loadingSpinner.scss'

function LoadingSpinner(props: any) {
    const { percentage = false } = props
    return (
        <section className='loading_spinner'>
            {percentage && <div className='percentage_loading'>{percentage}</div>}
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}

export function GridStyleLoader() {
    return (
        <section className='instagram_loader'>
            <div className='lds-instagram'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </section>
    )
}

export default LoadingSpinner
