import React, {
    ReactElement,
    useRef,
    useState,
    useEffect,
    useCallback,
    CSSProperties,
    useMemo
} from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColumnState, GridColumnsChangedEvent, GridReadyEvent, SideBarDef } from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import useLocalStorage from 'use-local-storage'
import './Grid.scss'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import SimpleTextbox from '../Textbox/SimpleTextbox'
import { Stack } from '@fluentui/react'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useLocation } from 'react-router-dom'
import routes from '../../../routes/routes'
import excelicon from '../../../assets/docs/icons8-excel-96.svg'
import pdficon from '../../../assets/docs/icons8-acrobat-67.png'
import { DraftJSRenderText, isValidJSON } from '../../../utils/COMMON'
import { alphaNumeric } from 'utils/REGEX'
import CustomToolPanel from '../CustomToolPanel/CustomToolPanel'

const stackTokens = { childrenGap: 10 }

const exportStackContainer = {
    marginBottom: 10,
    justifyContent: 'flex-end',
    marginTop: -55
}
const defaultColDef = {
    sortable: true,
    filter: true,
    wrapText: true,
    autoHeight: true,
    resizable: true,
    enablePivot: false,
    suppressMenu: true,
    editable: false
}
type customActions = { name: string; action: any }

type props = {
    className?: string
    gridOptions?: any
    rowData: any
    columnDefination?: any
    alignedGrid?: boolean
    onCellClicked?: any
    actionButtons?: any
    actionButtonsforCarCase?: any
    cellRendererCustomName?: string
    cellRendererCustom?: any
    onGridReady?: any
    onSelectionChanged?: any
    sideBar?: any
    name?: string
    setGridApi?: any
    customActions?: customActions[]
    paginationPageSize?: number
    pagination?: boolean
    groupRows?: boolean
    animateRows?: boolean
    height?: string
    width?: string
    isQuickSearch?: boolean
    domLayout?: any
    defColDef?: any
    tooltipShowDelay?: number
    tooltipHideDelay?: number
    containerHeight?: string
    isSideBarFilter?: boolean
    enableExportToPdf?: boolean
    enableExportToExcel?: boolean
    onCellValueChanged?: any
    rowSelection?: any
    suppressClickEdit?: boolean
}

const gridPadding10Css: CSSProperties = { padding: '10px 0px' }
const gridPaddingCss: CSSProperties = { marginTop: 7 }
const Grid: React.FC<props> = (props: props): ReactElement => {
    const location = useLocation()
    const currentRoute = routes.filter((item: any) => item.path === location.pathname)[0]
    LicenseManager.setLicenseKey(
        'Using_this_AG_Grid_Enterprise_key_( AG-039895 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Volvo Car AB )_is_granted_a_( Multiple Applications )_Developer_License_for_( 4 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released before_( 11 April 2024 )____[v2]_MTcxMjc5MDAwMDAwMA==6f77f14d4c5ca2cd717fcee936dda988'
    )

    const {
        className,
        gridOptions,
        rowData,
        columnDefination,
        actionButtons,
        onCellClicked,
        cellRendererCustom,
        cellRendererCustomName,
        onGridReady,
        onSelectionChanged,
        sideBar = true,
        name = 'default',
        setGridApi,
        customActions,
        paginationPageSize = 10,
        pagination = true,
        groupRows = false,
        animateRows = false,
        height = 'auto',
        width = '-webkit-fill-available',
        isQuickSearch,
        domLayout = 'autoHeight',
        tooltipShowDelay = 0,
        tooltipHideDelay = 2000,
        containerHeight,
        enableExportToPdf = false,
        enableExportToExcel = false,
        onCellValueChanged = undefined,
        rowSelection = 'single',
        suppressClickEdit = true
    } = props
    const gridRef = useRef<any>(null)
    const [cookies, setCookie] = useLocalStorage<ColumnState[]>(name, [])
    const actionComp: any = {
        actionCellRenderer: actionButtons
    }
    const [searchFilterValue, setSearchFilterValue] = useState('')
    if (cellRendererCustomName) {
        actionComp[cellRendererCustomName] = cellRendererCustom
    }
    if (customActions) {
        customActions.forEach(
            (custom: { name: string | number; action: any }) =>
                (actionComp[custom.name] = custom.action)
        )
    }

    const defaultSidebarColumns = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return props?.isSideBarFilter
            ? {
                  toolPanels: [
                      {
                          id: 'columns',
                          labelDefault: 'Columns',
                          labelKey: 'columns',
                          iconKey: 'columns',
                          toolPanel: 'agColumnsToolPanel',
                          toolPanelParams: {
                              suppressPivotMode: true,
                              suppressRowGroups: true,
                              suppressValues: true,
                              suppressColumnFilter: true,
                              suppressColumnSelectAll: true,
                              suppressColumnExpandAll: true
                          }
                      },
                      {
                          id: 'filters',
                          labelDefault: 'Filters',
                          labelKey: 'filters',
                          iconKey: 'filter',
                          toolPanel: 'agFiltersToolPanel'
                      },
                      {
                          id: 'customOptions',
                          labelDefault: 'Options',
                          labelKey: 'customOptions',
                          iconKey: 'menu',
                          toolPanel: CustomToolPanel,
                          toolPanelParams: {
                              name: name
                          }
                      }
                  ],
                  defaultToolPanel: ''
              }
            : {
                  toolPanels: [
                      {
                          id: 'columns',
                          labelDefault: 'Columns',
                          labelKey: 'columns',
                          iconKey: 'columns',
                          toolPanel: 'agColumnsToolPanel',
                          toolPanelParams: {
                              suppressPivotMode: true,
                              suppressRowGroups: true,
                              suppressValues: true,
                              suppressColumnFilter: true,
                              suppressColumnSelectAll: true,
                              suppressColumnExpandAll: true
                          }
                      },
                      {
                          id: 'customOptions',
                          labelDefault: 'Options',
                          labelKey: 'customOptions',
                          iconKey: 'menu',
                          toolPanel: CustomToolPanel,
                          toolPanelParams: {
                              name: name
                          }
                      }
                  ],
                  defaultToolPanel: ''
              }
    }, [])

    const gridContainerStyle = {
        height: height,
        width: width,
        paddingTop: '0px',
        marginBottom: 0
    }
    const onGridReadyCustom = (event: GridReadyEvent) => {
        if (setGridApi) setGridApi(event.api)
        if (name) {
            event.columnApi.applyColumnState({ state: cookies, applyOrder: true })
        }
        if (onGridReady) {
            onGridReady(event)
        }
        if (name === 'Column-MyesowGrid') {
            const pageToNavigate = Number(sessionStorage.getItem('EsowGridCurrentPage') || 0)
            event.api.paginationGoToPage(pageToNavigate)
        }
    }

    const onColumnChanged = (event: GridColumnsChangedEvent) => {
        if (name) {
            const currentState = event.columnApi.getColumnState()
            setCookie(currentState)
        }
    }

    const containerStyle: any = { paddingLeft: '8px', width: width }

    if (containerHeight) {
        containerStyle.height = containerHeight
    }

    const onFilterTextBoxChanged = useCallback((text: any) => {
        gridRef?.current?.api?.setQuickFilter(text)
    }, [])

    const onPaginationChanged = (params: any) => {
        if (name === 'Column-MyesowGrid' && params.newPage) {
            const currentPage = params.api.paginationGetCurrentPage()
            sessionStorage.setItem('EsowGridCurrentPage', JSON.stringify(currentPage))
        }
    }

    useEffect(() => {
        onFilterTextBoxChanged(searchFilterValue)
    }, [searchFilterValue])

    function exportToPdf() {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({ orientation: 'landscape' })

        const gridApi = gridRef.current.api
        const columnDefs = gridApi.getColumnDefs()
        const headers = columnDefs.map((column: any) => column.headerName)

        const rowData: any = []
        gridApi.forEachNode((node: any) => rowData.push(node.data))

        const tableData = [
            ...rowData.map((row: any) => columnDefs.map((column: any) => row[column.field]))
        ]

        autoTable(doc, {
            head: [headers],
            body: tableData
        })
        doc.save(currentRoute ? `${currentRoute.text}.pdf` : `export.pdf`)
    }

    function exportToExcel() {
        const gridApi = gridRef.current.api
        const exportParams = {
            fileName: currentRoute ? `${currentRoute.text}.xlsx` : `export.xlsx`,
            processCellCallback: (params: any) => {
                const { value } = params
                if (value && isValidJSON(value)) {
                    return DraftJSRenderText(value)
                }
                return value
            }
        }
        gridApi.exportDataAsExcel(exportParams)
    }

    return (
        <div
            className={`ag-theme-alpine ${className !== undefined ? ' ' + className : ''}`}
            style={gridContainerStyle}
        >
            {isQuickSearch && (
                <Stack horizontalAlign='start' style={gridPadding10Css}>
                    <Stack.Item>
                        <SimpleTextbox
                            placeholder='Quick Search'
                            regex={alphaNumeric}
                            value={searchFilterValue}
                            onChange={(item: string) => setSearchFilterValue(item)}
                        />
                    </Stack.Item>
                </Stack>
            )}
            <>
                {rowData?.length > 0 && (enableExportToPdf || enableExportToExcel) && (
                    <Stack horizontal tokens={stackTokens} style={exportStackContainer}>
                        {enableExportToPdf && (
                            <Stack.Item>
                                <img
                                    src={pdficon}
                                    alt='Export to pdf'
                                    title='Export to pdf'
                                    width='32'
                                    style={gridPaddingCss}
                                    onClick={exportToPdf}
                                    className='exportButton'
                                />
                            </Stack.Item>
                        )}
                        {enableExportToExcel && (
                            <Stack.Item>
                                <img
                                    src={excelicon}
                                    alt='Export to excel'
                                    title='Export to excel'
                                    width='45'
                                    onClick={exportToExcel}
                                    className='exportButton'
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                )}

                <AgGridReact
                    ref={gridRef}
                    frameworkComponents={actionComp}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    gridOptions={gridOptions}
                    suppressDragLeaveHidesColumns={false}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    onCellClicked={onCellClicked || null}
                    enableRangeSelection={true}
                    columnDefs={columnDefination}
                    colResizeDefault='shift'
                    rowSelection={rowSelection}
                    suppressRowClickSelection
                    suppressRowDeselection
                    suppressContextMenu
                    onGridReady={(event) => onGridReadyCustom(event)}
                    onSelectionChanged={onSelectionChanged}
                    sideBar={sideBar && defaultSidebarColumns}
                    cacheQuickFilter
                    onColumnMoved={(event) => onColumnChanged(event)}
                    onColumnVisible={(event) => onColumnChanged(event)}
                    groupDisplayType={groupRows ? 'groupRows' : 'multipleColumns'}
                    animateRows={animateRows}
                    groupDefaultExpanded={1}
                    domLayout={!containerHeight ? domLayout : 'normal'}
                    tooltipShowDelay={tooltipShowDelay}
                    tooltipHideDelay={tooltipHideDelay}
                    alwaysShowVerticalScroll={!!containerHeight}
                    suppressAutoSize={false}
                    suppressBrowserResizeObserver
                    onCellValueChanged={onCellValueChanged}
                    suppressClickEdit={suppressClickEdit}
                    onPaginationChanged={onPaginationChanged}
                    className='custom-ag-grid'
                />
            </>
        </div>
    )
}

export default Grid
