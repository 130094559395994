import * as React from 'react'
import { Stack } from '@fluentui/react/lib/Stack'
import {
    stackItemStyles,
    outerStackTokens,
    innerStackTokens,
    itemContainerPadding
} from './PageBannerStyles'
import { useEffect, useState } from 'react'
import { _getLatestVersion } from '../../../services/API/_changeLog'
import ChangeLogGridModelView from 'components/pages/ChangeLog/ChangeLogGridModelView'

const backgroundColor = {
    background: '#141414',
    color: '#FFF'
}
const pageHeaderStyle: React.CSSProperties = {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase'
}
const pageVersionStyle: React.CSSProperties = {
    paddingTop: '10px',
    paddingRight: '20px',
    color: '#b1b1b1',
    fontWeight: 600,
    cursor: 'pointer'
}

interface IPageBaneer {
    heading: string
    subHeading?: string
}
export const PageBannerTop: React.FunctionComponent<IPageBaneer> = ({ heading }: IPageBaneer) => {
    const [data, setData] = useState<any>()
    const [panelOpen, setPanelOpen] = useState(false)

    useEffect(() => {
        const getLatestVersion = async () => {
            setTimeout(async () => {
                await _getLatestVersion(setData)
            }, 700)
        }

        getLatestVersion()
    }, [])

    return (
        <>
            <Stack
                enableScopedSelectors
                tokens={outerStackTokens}
                styles={{
                    root: backgroundColor
                }}
                className='pageBannerTop'
            >
                <Stack
                    enableScopedSelectors
                    styles={stackItemStyles}
                    tokens={innerStackTokens}
                    horizontalAlign='space-between'
                    horizontal
                >
                    <Stack.Item style={itemContainerPadding}>
                        <div style={pageHeaderStyle}>{heading}</div>
                    </Stack.Item>
                    <Stack.Item>
                        <div style={pageVersionStyle} onClick={() => setPanelOpen(true)}>
                            {data && data?.version}
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack>
            <ChangeLogGridModelView
                panelOpen={panelOpen}
                setPanelOpen={setPanelOpen}
                onDismiss={() => setPanelOpen(false)}
            />
        </>
    )
}
