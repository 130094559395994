import React from 'react'
import { IToolPanelParams } from 'ag-grid-community'
import ButtonComponent from '../Button'
import Label from '../Label'

interface CustomToolPanelParams extends IToolPanelParams {
    name: string
}

const CustomToolPanel = (props: CustomToolPanelParams) => {
    const { name, api, columnApi } = props

    const resetCookie = () => {
        columnApi.resetColumnState()
        setTimeout(() => {
            localStorage.removeItem(name)
            localStorage.removeItem('default')
        }, 300)
        api.closeToolPanel()
    }

    return (
        <div style={{ paddingLeft: '10px' }}>
            <span>
                <h3>Options</h3>
                <Label
                    labelName={'Click on this button to show default columns and column order.'}
                    style={{ paddingBottom: 10 }}
                />
                <ButtonComponent text={'Reset grid to default'} onClick={resetCookie} />
            </span>
        </div>
    )
}

export default CustomToolPanel
