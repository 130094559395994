import { ContentState, EditorState, convertFromRaw } from 'draft-js'
import React from 'react'
import DraftEditor from '../components/atoms/TextEditor/DraftEditor'
import { BASE_URL, SAVE_ESOW_MAIN } from './ENDPOINTS'
import { ApiService } from 'services/apiUtils'
import { LOG_MESSAGES } from './ERRORMESSAGES'
import { MessageBarType } from '@fluentui/react'
import ReactDOMServer from 'react-dom/server'

// Function to encrypt a string
export const encodeId = (id: any) => {
    const encodedId = btoa(`${id}-esowDocumentId`)

    return encodedId
}

// Function to decrypt a string
export const decodeId = (encodedId: any) => {
    const decodedId = atob(encodedId)?.split('-')[0]

    return decodedId
}

export const isValidJSON = (obj: any) => {
    try {
        const checkJson = JSON.parse(obj)
        if (typeof checkJson === 'object') {
            return true
        }
        return false
    } catch (e) {
        return false
    }
}

export function pushUnique(arr: any[], obj: { attributeId: any; projectId: any }) {
    const index = arr.findIndex(
        (item: { attributeId: any; projectId: any }) =>
            item.attributeId === obj.attributeId && item.projectId === obj.projectId
    )

    if (index !== -1) {
        arr[index] = obj
    } else {
        arr.push(obj)
    }
    return arr
}

export const DraftJsStaticRenderer = (response: any) => {
    const rawTxt = isValidJSON(response) ? JSON.parse(response) : response
    return (
        <div style={{ lineHeight: 'normal' }}>
            <DraftEditor
                readOnly={true}
                editorState={
                    response === null || response === ''
                        ? EditorState.createEmpty()
                        : EditorState.createWithContent(convertFromRaw(rawTxt))
                }
                toolbarHidden
                staticContentNoStyle
            />
        </div>
    )
}

export function checkConvertStrtoDraftJs(response: any) {
    return isValidJSON(response)
        ? convertFromRaw(JSON.parse(response))
        : ContentState.createFromText(response)
}
export const DraftJSRenderText = (response: any) => {
    if (isValidJSON(response)) {
        const contentState = convertFromRaw(JSON.parse(response))
        const plainText = contentState.hasText() ? contentState.getPlainText('\n') : ''
        return plainText
    } else {
        return response
    }
}

export function nl2br(str: string): JSX.Element[] {
    return str
        .split('\n')
        .map((line, index) => <React.Fragment key={`news-itm-${index}`}>{line}</React.Fragment>)
}

export const editModeUpdate = async (
    rulesSet: any,
    updateRulesSet: (arg0: any) => any,
    setLoader: (arg0: boolean) => void,
    editMode: any,
    paragraphId: any,
    unSavedChanges?: boolean,
    setUnSavedChanges?: any,
    setShowUnsavedModal?: any
) => {
    setLoader(true)
    const updatedRulesSet = {
        ...rulesSet,
        esowParagraphsList: rulesSet?.esowParagraphsList.map((item: any) => {
            if (item.paragraphId === paragraphId) {
                return {
                    ...item,
                    editMode: !editMode
                }
            }
            return item
        })
    }
    if (unSavedChanges !== undefined && unSavedChanges) {
        setLoader(false)
        setShowUnsavedModal(true)
    } else {
        setTimeout(async () => {
            await updateRulesSet(updatedRulesSet)
            setLoader(false)
        }, 2000)
    }
}

interface ISaveIndividualParagraphProps {
    updatedRulesSet: any
    paragraphId: number
    sliderCompletion?: number
    sliderCompletionVCC?: number
    documentId?: string
    setNotifications: any
}
export async function saveIndividualParagraph(props: ISaveIndividualParagraphProps) {
    const {
        updatedRulesSet,
        paragraphId,
        sliderCompletion,
        sliderCompletionVCC,
        documentId,
        setNotifications
    } = props
    const currentParagraphObj = updatedRulesSet?.esowParagraphsList.filter(
        (item: any) => item.paragraphId === paragraphId
    )

    const currentParagraphUpdatedValue = currentParagraphObj.map((item: any) => {
        if (item.paragraphId === paragraphId) {
            return {
                ...item,
                completionStatus: sliderCompletion,
                completionStatusVCC: sliderCompletionVCC
            }
        }
        return item
    })

    const pathName = `${BASE_URL}${SAVE_ESOW_MAIN}`
    const properties: any = {
        esowDocumentId: documentId,
        esowHeader: null,
        esowSelectedCategoryList: updatedRulesSet?.esowSelectedCategoryList || null,
        esowParagraphDataList: currentParagraphUpdatedValue || []
    }

    const requestObj: any = {
        path: pathName,
        method: 'POST',
        payload: properties
    }
    await ApiService(requestObj)
        .then((res: any) => {
            setNotifications(LOG_MESSAGES.ADD_SUCCESS_MSG, MessageBarType.success)
            return res.data
        })
        .catch((error: any) => {
            setNotifications(error, MessageBarType.error)
        })
}

interface ConvertToHTMLProps {
    targetElementId: string
    signerAutoTable: any
    fileName: string
}

export const downloadHtml = async ({
    targetElementId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signerAutoTable,
    fileName
}: ConvertToHTMLProps) => {
    const targetElement = document.getElementById(targetElementId)
    if (!targetElement) {
        alert(`Element with id "${targetElementId}" not found.`)
        return
    }

    const collectGlobalStyles = () => {
        let styles = ''
        const styleTags = document.querySelectorAll('style')
        styleTags.forEach((styleTag) => {
            styles += styleTag.innerHTML
        })
        const linkTags = document.querySelectorAll('link[rel="stylesheet"]')
        linkTags.forEach((linkTag) => {
            const href = linkTag.getAttribute('href')
            if (href) {
                fetch(href)
                    .then((response) => response.text())
                    .then((css) => {
                        styles += css
                    })
            }
        })

        return styles
    }

    const globalStyles = collectGlobalStyles()
    const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>SCOT</title>
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
                                    Roboto, "Helvetica Neue", sans-serif;
                    }
                    .centered-content {
                        display: flex;
                        justify-content: center;
                        align-items: left;
                        margin-bottom: 30px;
                        width: 90%;
                    }
                    .headStyles {
                        fill-color: '#dde2eb';
                        text-aign: 'left';
                        padding: 5px;
                    }
                    .rowStyles {
                        fontSize: 8px;
                        textColor: '#181d1f';
                        lineColor: '#ccc';
                        lineWidth: 0.2;
                    }
                    .sigColStyle {
                        text-align: 'left';
                        font-size: 15
                    }
                    .TEXT_BLOCK {
                        align-items: flex-start;
                        display: flex;
                        font-size: 14px;
                        font-weight: normal;
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }
                    .TEXT_HEADING_MODAL {
                        align-items: flex-start;
                        display: inline-block;
                        font-size: 18px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        margin-right: 10px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 35px;
                        color: rgb(32, 31, 30);
                    }
                    .TEXT_HEADING {
                        align-items: flex-start;
                        display: inline-block;
                        font-size: 16px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        margin-right: 10px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        color: #7089ac;
                    }

                    .TEXT_SUB_HEADING {
                        align-items: flex-start;
                        display: flex;
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 5px;
                        margin-bottom: 10px;
                    }

                    .TEXT_SEMI_SUB_HEADING {
                        align-items: flex-start;
                        display: flex;
                        font-size: 14px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        font-weight: 600;
                        color: rgb(32, 31, 30);
                    }

                    .TEXT_NOTE {
                        align-items: flex-start;
                        display: flex;
                        font-size: 13px;
                        font-style: italic;
                        margin-top: 5px;
                        margin-bottom: 10px;
                    }
                    .esowheader-table {
                        width: 100%;
                        border-collapse: collapse;
                        tr {
                            padding: 0;
                            height: 70px;
                        }
                        td {
                            width: 20rem;
                            vertical-align: top;
                            padding: 0 20px 10px 2px;
                            min-width: 100px;
                            max-width: 400px;
                        }
                        .esowheader-title {
                            padding: 10px 20px 0 2px;
                            display: grid;
                            grid-template-columns: 16rem 4rem;
                        }
                    }
                    .page-break {
                        page-break-before: always;
                    }

                    .no-break {
                        page-break-inside: avoid;
                    }
                    ${globalStyles}
                    ${getComputedStyle(targetElement).cssText}
                </style>
                <link
                    rel="stylesheet"
                    href="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/office-ui-fabric-core/11.1.0/css/fabric.min.css"
                />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet">
            </head>
            <body>
                ${targetElement.outerHTML}
            </body>
        </html>
        `
    const file = new File([htmlContent], fileName, { type: 'text/html' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(file)
    link.download = fileName
    link.click()
    URL.revokeObjectURL(link.href)
    // return file
}

// new export html function
const GlobalStyles = `
    body {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
                    Roboto, "Helvetica Neue", sans-serif;
    }
    .centered-content {
        display: flex;
        justify-content: center;
        align-items: left;
        margin-bottom: 30px;
        width: 90%;
    }
    .headStyles {
        fill-color: '#dde2eb';
        text-aign: 'left';
        padding: 5px;
    }
    .rowStyles {
        fontSize: 8px;
        textColor: '#181d1f';
        lineColor: '#ccc';
        lineWidth: 0.2;
    }
    .sigColStyle {
        text-align: 'left';
        font-size: 15
    }
    .TEXT_BLOCK {
        align-items: flex-start;
        display: flex;
        font-size: 14px;
        font-weight: normal;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .TEXT_HEADING_MODAL {
        align-items: flex-start;
        display: inline-block;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        color: rgb(32, 31, 30);
    }
    .TEXT_HEADING {
        align-items: flex-start;
        display: inline-block;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #7089ac;
    }

    .TEXT_SUB_HEADING {
        align-items: flex-start;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .TEXT_SEMI_SUB_HEADING {
        align-items: flex-start;
        display: flex;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 600;
        color: rgb(32, 31, 30);
    }

    .TEXT_NOTE {
        align-items: flex-start;
        display: flex;
        font-size: 13px;
        font-style: italic;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .esowheader-table {
        width: 100%;
        border-collapse: collapse;
        tr {
            padding: 0;
            height: 70px;
        }
        td {
            width: 20rem;
            vertical-align: top;
            padding: 0 20px 10px 2px;
            min-width: 100px;
            max-width: 400px;
        }
        .esowheader-title {
            padding: 10px 20px 0 2px;
            display: grid;
            grid-template-columns: 16rem 4rem;
        }
    }
    .page-break {
        page-break-before: always;
    }

    .no-break {
        page-break-inside: avoid;
    }
  `

export const ExportHTML = async ({ targetElementId, fileName }: any) => {
    const targetElement = document.getElementById(targetElementId)

    if (!targetElement) {
        alert(`Element with id "${targetElementId}" not found.`)
        return null
    }

    // Collect global styles dynamically
    const collectGlobalStyles = () => {
        let styles = ''
        const styleTags = document.querySelectorAll('style')
        styleTags.forEach((styleTag) => {
            styles += styleTag.innerHTML
        })
        const linkTags = document.querySelectorAll('link[rel="stylesheet"]')
        linkTags.forEach((linkTag) => {
            const href = linkTag.getAttribute('href')
            if (href) {
                fetch(href)
                    .then((response) => response.text())
                    .then((css) => {
                        styles += css
                    })
            }
        })
        return styles
    }

    const globalStyles = collectGlobalStyles()

    // Create HTML string with React components
    const htmlContent = await ReactDOMServer.renderToStaticMarkup(
        <html lang='en'>
            <head>
                <meta charSet='UTF-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                <title>Exported HTML</title>
                <style>{GlobalStyles}</style>
                <style>{globalStyles}</style>
                <link
                    rel='stylesheet'
                    href='https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/office-ui-fabric-core/11.1.0/css/fabric.min.css'
                />
                <link
                    href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
                    rel='stylesheet'
                />
            </head>
            <body>
                <div dangerouslySetInnerHTML={{ __html: targetElement.outerHTML }} />
                {/* <div className='centered-content'>
                    <div
                        className='custom-table-container'
                        style={{ width: '100%', marginLeft: '60px', marginBottom: '30px' }}
                    >
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>ROLE</th>
                                    <th>SIGNATURE</th>
                                    <th>EMAIL</th>
                                </tr>
                            </thead>
                            <tbody>
                                $
                                {signerAutoTable
                                    .map((row: any, index: number) => (
                                        <tr className='rowStyles' key={`signerCount-${index}`}>
                                            <td className='sigColStyle'>{row[0]}</td>
                                            <td className='sigColStyle'>{row[1]}</td>
                                            <td className='sigColStyle'>{row[2]}</td>
                                            <td className='sigColStyle'>{row[3]}</td>
                                        </tr>
                                    ))
                                    .join('')}
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </body>
        </html>
    )

    // Convert to File object
    const file = new File([htmlContent], fileName, { type: 'text/html' })
    // const link = document.createElement('a')
    // link.href = URL.createObjectURL(file)
    // link.download = fileName
    // link.click()
    // URL.revokeObjectURL(link.href)

    return file
}

export function generateUniqueCodeId(length: number) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let code = ''
    for (let i = 0; i < length; i++) {
        if (i % 3 === 0 && i !== 0) {
            code += Math.floor(Math.random() * 10) // Add a number every 3rd character
        } else {
            code += chars.charAt(Math.floor(Math.random() * chars.length))
        }
    }
    return code
}
